import * as React from "react"

import { SEO } from "../components/seo"
import { Contact } from "../components/Contact"
import { PrimaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import { Link } from "gatsby"
import { clearSnappy } from "../model/helpers"

const logo = require("../images/box_logo.png")
const trending90s = require("../images/about-us/90s.jpg")
const trendingCar = require("../images/about-us/car.jpg")
const trendingSports = require("../images/about-us/sports.jpg")
const trendingSushi = require("../images/about-us/sushi.jpg")
const branded = require("../images/about-us/branded-1.jpg")
const charles = require("../images/about-us/charles_headshot.jpg")
const top_bg = require("../images/about-us/top_bg.jpg")
const stats_bg = require("../images/about-us/stats_bg.jpg")
const branded_bg = require("../images/about-us/branded_bg.jpg")
const contact_bg = require("../images/about-us/contact_bg.jpg")

const AboutUs = () => (
  <>
    {clearSnappy()}
    <PrimaryHeader slug="about-us" />
    <SEO isQuiz={true} title="Contact Policulous Today" url="about-us" />
    <div className="container-fluid">
      <div
        className="row parallax top-bg pad-top-250 pad-bottom-250"
        style={{ backgroundImage: "url(" + top_bg + ")" }}
      >
        <div className="col-xs-12">
          <div className="container align-center">
            <div className="row pad-top-20">
              <div className="col-xs-12 page-subheadline">WELCOME</div>
            </div>
            <div className="row">
              <div className="col-xs-12 page-headline">WE ENTERTAIN THE WORLD</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row white-bg pad-bottom-80 pad-top-80">
        <div className="col-xs-12">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <p><strong>Welcome!</strong></p>
                <p>Welcome to our trivia and news website, where we strive to engage, inform, and entertain our audience through a diverse tapestry of captivating content. At our core, we are dedicated to delivering a seamless blend of knowledge, current affairs, and interactive trivia, catering to the intellect and curiosity of our global audience.</p>

                <p><strong>Our Mission</strong></p>
                <p>Our mission is to be a premier destination for individuals seeking to expand their knowledge, stay abreast of current events, and indulge in the joy of trivia. We are committed to fostering a vibrant community of inquisitive minds, offering a platform that transcends boundaries and unites individuals through the power of information and shared experiences.</p>

                <p><strong>Ownership</strong></p>
                <p>TriviaBoss was founded in 2019 by Charles Lumpkin who serves as the President & Publisher. The website is owned by the Executive Team at Grey State Ventures. Our founders are successful entrepreneurs with exits in technology, advertising, law and retail businesses. Charles is an Atlanta-based entrepreneur with more than 15 years of experience in technology and advertising. Charles has demonstrated strong leadership and an ability to create processes that focus on achieving scale. He sold his first company at the age of 28 to social marketing leader, BLiNQ Media. Charles led the media and product teams at BLiNQ where they created unique, proprietary tools that won Fortune 500 clients like Johnson & Johnson and Toyota. BLiNQ's impressive client portfolio attracted the attention of international publishing giant Gannett, who acquired the company in August of 2012.</p>

                <p><strong>Editorial Team</strong></p>
                <p>Emma Johnson serves as Editor-in-Chief of TriviaBoss. She is a graduate of Arizona State University and lives in Denver, CO where she works as a journalist. In 2024, TriviaBoss partnered with JustWill LLC and MediaVerse LLC for syndication of its content. TriviaBoss allows 3rd parties to republish its content for syndication purposes, including Microsoft News (MSN), Apple News, Yahoo News, Newsbreak, AOL, Google News, and others. To inquire about syndication opportunities, please contact us.</p>

                <p><strong>Correction Policy</strong></p>
                <p>TriviaBoss fact-checks all published content. The website publishes corrections at the top of articles.</p>

                <p><strong>Ethics Policy</strong></p>
                <p>The website is transparent with readers. The site is independent and has no ties to lobbyists, politicians, or special interest groups. The site does not accept any funding from foreign governments or individuals. The website is exclusively funded through 3rd party online ads. You may opt out of cookie-based advertising and manage your privacy settings. Employees and independent contractors of TriviaBoss are expected to act lawfully, honestly, ethically, and in the best interests of the company while performing duties on behalf of TriviaBoss.com. To report a violation of our Ethics Policy, please contact us.</p>

                <p><strong>What We Offer</strong></p>
                <p>
                  <ul>
                    <li>Engaging Trivia: Immerse yourself in a world of fascinating facts, brain-teasing quizzes, and delightful trivia that spans an array of topics, from history and science to pop culture and beyond.</li>
                    <li>Timely News Updates: Stay informed with our comprehensive news coverage, encompassing a spectrum of global, national, and local events, ensuring that you are equipped with the latest developments shaping our world.</li>
                    <li>Interactive Community: Join a community of passionate learners and news enthusiasts, where you can share insights, exchange ideas, and revel in the camaraderie of like-minded individuals.</li>
                  </ul>
                </p>

                <p><strong>Our Commitment</strong></p>
                <p>We are unwavering in our commitment to delivering content that is informative, thought-provoking, and entertaining. Our team of dedicated writers, researchers, and editors endeavors to curate a diverse array of content that resonates with our audience, fostering an enriching and immersive experience for all who visit our platform.</p>

                <p><strong>Connect With Us</strong></p>
                <p>We invite you to embark on a journey of discovery and enlightenment with us. Join our community, explore our content, and immerse yourself in the boundless world of trivia and news. Together, let's celebrate the joy of learning and the thrill of staying informed.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr></hr>

      <div className="row white-bg pad-bottom-80 pad-top-80">
        <div className="col-xs-12">
          <div className="container align-center">
            <div className="row">
              <div className="col-xs-12 section-subheadline orange">OUR GOAL IS SIMPLE:</div>
            </div>
            <div className="row">
              <div className="col-xs-12 section-headline grey">
                BRING JOY THROUGH
                <br />
                FASCINATING EXPERIENCES
              </div>
            </div>
            <div className="row pad-top-20">
              <div className="col-md-4 col-md-offset-4">
                <div className="row">
                  <div className="col-sm-12">
                    <img
                      src={logo}
                      className="vc_single_image-img attachment-thumbnail"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">TriviaBoss.com</div>
                </div>
                <div className="row">
                  <div className="col-sm-12">Addictive Quizzes That People Love</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row parallax stats-bg" style={{ backgroundImage: "url(" + stats_bg + ")" }}>
        <div className="col-xs-12">
          <div className="container align-center">
            <div className="row pad-top-100 pad-bottom-100">
              <div className="col-sm-3">
                <div className="row pad-top-40 pad-bottom-40">
                  <div className="col-xs-12 section-subheadline orange ">THE NUMBERS DON'T LIE, BRO</div>
                  <div className="col-xs-12 section-headline white">STATS</div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="row">
                  <div className="col-xs-12">
                    <i className="fas fa-thermometer-full about-icon white" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 icon-headline orange">140</div>
                </div>
                <div className="row pad-bottom-40">
                  <div className="col-xs-12 section-subheadline white">
                    MILLION AD IMPRESSIONS
                    <br />
                    EVERY MONTH
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="row">
                  <div className="col-xs-12">
                    <i className="fas fa-signal  about-icon white" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 icon-headline orange">60</div>
                </div>
                <div className="row pad-bottom-40">
                  <div className="col-xs-12 section-subheadline white">
                    MILLION AD PAGEVIEWS
                    <br />
                    EVERY MONTH
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="row">
                  <div className="col-xs-12">
                    <i className="fas fa-calendar-alt  about-icon white" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 icon-headline orange">1047</div>
                </div>
                <div className="row pad-bottom-40">
                  <div className="col-xs-12 section-subheadline white">
                    DAYS OF FREE TIME
                    <br />
                    SPENT ON SITE YESTERDAY
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row white-bg">
        <div className="col-xs-12">
          <div className="container align-center">
            <div className="row pad-top-80 pad-bottom-80">
              <div className="col-sm-4">
                <div className="row pad-top-100 pad-bottom-40">
                  <div className="col-xs-12 section-subheadline orange">SOME OF OUR</div>
                  <div className="col-xs-12 section-headline grey">
                    TRENDING <br />
                    CONTENT
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-12 pad-top-20">
                    <a href={process.env.GATSBY_URL_PREFIX + "/90s"} className="title-link">
                      How Well Do You Really Know 90s Music?
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <a href={process.env.GATSBY_URL_PREFIX + "/90s"} className="title-link">
                      <img
                        src={trending90s}
                        className="vc_single_image-img attachment-large"
                        style={{ width: "650px", height: "340px" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 section-subheadline orange pad-top-20">
                    <a href={process.env.GATSBY_URL_PREFIX + "/world-cuisine"} className="title-link">
                      Put Your Culinary Skills To The Test
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <a href={process.env.GATSBY_URL_PREFIX + "/world-cuisine"} className="title-link">
                      <img
                        src={trendingSushi}
                        className="vc_single_image-img attachment-large"
                        style={{ width: "650px", height: "340px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-12 section-subheadline orange pad-top-20">
                    <a href={process.env.GATSBY_URL_PREFIX + "/porsche"} className="title-link">
                      Zoom Through This Porsche Challenge
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <a href={process.env.GATSBY_URL_PREFIX + "/porsche"} className="title-link">
                      <img
                        src={trendingCar}
                        className="vc_single_image-img attachment-large"
                        style={{ width: "650px", height: "340px" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 section-subheadline orange pad-top-20">
                    <a href={process.env.GATSBY_URL_PREFIX + "/sports-challenge"} className="title-link">
                      Can You Ace The Ultimate Sports Challenge?
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <a href={process.env.GATSBY_URL_PREFIX + "/sports-challenge"} className="title-link">
                      <img
                        src={trendingSports}
                        className="vc_single_image-img attachment-large"
                        style={{ width: "650px", height: "340px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row parallax brand-bg pad-bottom-80 pad-top-80"
        style={{ backgroundImage: "url(" + branded_bg + ")" }}
      >
        <div className="col-xs-12">
          <div className="container align-center">
            <div className="row">
              <div className="col-xs-12 section-subheadline orange">EXCLUSIVE</div>
            </div>
            <div className="row pad-bottom-40">
              <div className="col-xs-12 section-headline white">BRANDED CONTENT</div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12 larger-body-font orange">What Is Your Bridal Style?</div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <img
                      src={branded}
                      className="vc_single_image-img attachment-large"
                      style={{ width: "650px", height: "340px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row pad-top-20">
                  <div className="col-sm-12 align-left orange section-subsubheadline">
                    ENGAGING CUSTOMERS DOESN'T HAVE TO BE SO HARD
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 align-left pad-top-10  larger-body-font white">
                    Trivia Boss Works With Brands To:
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 align-left larger-body-font white">
                    <ul>
                      <li>Drive sales and increase social media engagement</li>
                      <li>Grow email lists and make informed product recommendations</li>
                      <li>Generate leads with enriched profiles and enhanced personalization</li>
                    </ul>
                  </div>
                </div>
                <div className="row pad-top-20">
                  <div className="col-sm-12">
                    <a href="#contact" className="wbc-button button btn-primary no-hover btn-large">
                      CONTACT US TO LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row white-bg pad-bottom-80 pad-top-80">
        <div className="col-xs-12">
          <div className="container align-center">
            <div className="row">
              <div className="col-xs-12 section-subheadline orange">WHO WE ARE</div>
            </div>
            <div className="row pad-bottom-20">
              <div className="col-xs-12 section-headline grey">EXECUTIVE TEAM</div>
            </div>
            <div className="row  pad-bottom-20">
              <div className="col-sm-6 col-sm-offset-3 grey align-left">
                The Executive Team at Grey State Ventures focuses on creating fascinating experiences for consumers and
                driving scale. Our founders are successful serial entrepreneurs with exits in technology, advertising,
                law and retail businesses.
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-sm-offset-4">
                <div className="row">
                  <div className="col-md-12">
                    <img src={charles} alt="Charles Lumpkin" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 larger-body-font black">
                    Charles <span className="orange">Lumpkin</span>
                  </div>
                </div>
                <div className="row pad-bottom-10">
                  <div className="col-md-12">
                    <i>President &amp; Publisher</i>
                  </div>
                </div>
                <div className="row pad-bottom-40">
                  <div className="col-md-12 align-left">
                    Charles Lumpkin is an Atlanta-based entrepreneur with more than 15 years of experience in technology
                    and advertising. Charles has demonstrated strong leadership and an ability to create processes that
                    focus on achieving scale. He sold his first company at the age of 28 to social marketing leader,
                    BLiNQ Media.
                    <br />
                    <br />
                    Charles led the media and product teams at BLiNQ where they created unique, proprietary tools that
                    won Fortune 500 clients like Johnson &amp; Johnson and Toyota. BLiNQ's impressive client portfolio
                    attracted the attention of international publishing giant Gannett, who acquired the company in
                    August of 2012.
                    <br />
                    <br />
                    Charles lives in Atlanta with his wife, two kids and a teacup yorkie (for real - man card=gone).
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span className="anchor-link" id="contact" />
      <div
        className="row parallax contact-bg pad-top-80 pad-bottom-80"
        style={{ minHeight: "500px", backgroundImage: "url(" + contact_bg + ")" }}
      >
        <div className="col-md-12">
          <div className="container align-center">
            <div className="row">
              <div className="col-xs-12 section-subheadline orange">NEED A SHOULD TO CRY ON?</div>
            </div>
            <div className="row pad-bottom-40">
              <div className="col-xs-12 section-headline white">CONTACT US</div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <div className="row">
                  <div className="col-xs-2">
                    <i className="fas fa-mobile-alt small-about-icon orange" />
                  </div>
                  <div className="col-xs-10 align-left white larger-body-font bold">Phone:</div>
                </div>
                <div className="row">
                  <div className="col-xs-10 col-xs-offset-2 align-left white">(404) 618-0198</div>
                </div>
                <div className="row pad-top-20">
                  <div className="col-xs-2">
                    <i className="fas fa-map-marker-alt small-about-icon orange" />
                  </div>
                  <div className="col-xs-10 align-left white larger-body-font bold">Address:</div>
                </div>
                <div className="row">
                  <div className="col-xs-10 col-xs-offset-2 align-left white">
                    1372 Peachtree St. NE
                    <br />
                    Atlanta, Ga. 30309
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 align-left" style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                    <div className="about-map" />
                  </div>
                </div>
              </div>
              <div className="col-sm-9 section-subheadline orange">
                <Contact url="about-us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
)

export default AboutUs
